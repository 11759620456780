<script>
import Swal from 'sweetalert2';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    form: {
      email: '',
      password: null,
    },

    showPass: false,
    loading: false
  }),

  methods: {
    ...mapActions('auth', ['login']),

    async submit() {
      this.loading = true
      try {
        const valid = await this.$refs.form.validate()

        if (valid) {
          const res = await this.login(this.form)
          if (res) this.$router.push('/')
        }
      } catch (e) {
        const error = e.graphQLErrors[0]
        if (error.extensions.code === 'business') {
          Swal.fire({
            icon: 'error',
            text: error.message,
            position: 'top-end',
            toast: true,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
      this.loading = false
    },

    clear() {
      this.form = {
        email: '',
        password: null
      }
      this.$refs.form.reset()
    }
  }
}
</script>

<template>
  <div class="pa-5 pa-sm-15">
    <!-- display: flex;
    flex-direction: column;
    justify-content: center; -->
    <div>
      <!-- <h2 class="text-center mt-15">Bem-vindo ao Sorio</h2> -->
      <div class="text-center mt-15">
        <img src="@/assets/logo.png" width="80%"/>
      </div>
      <v-form ref="form" class="mt-10" @submit.prevent="submit">
        <v-text-field
          v-model="form.email"
          label="Email"
          outlined
          persistent-placeholder
        />
        <v-text-field
          v-model="form.password"
          label="Senha"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          outlined
          persistent-placeholder
          @click:append="showPass = !showPass"
        />
        <div class="text-center">
          <v-btn type="submit" x-large block color="success" :loading="loading">
            Entrar
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>
